import { createGlobalStyle } from "styled-components";
import InterRegular from "./fonts/Inter-Regular.ttf"; //400
import InterMedium from "./fonts/Inter-Medium.ttf"; //500
import InterSemiBold from "./fonts/Inter-SemiBold.ttf"; //600
import InterBold from "./fonts/Inter-Bold.ttf"; //700
import InterExtraBold from "./fonts/Inter-ExtraBold.ttf"; //800

const GlobalStyle = createGlobalStyle`

    //Fonts Imports
    @font-face {
    	font-family: "Inter";
        font-weight: 400;
    	src: url(${InterRegular}) format('truetype');
    }
    @font-face {
    	font-family: "Inter";
        font-weight: 500;
    	src: url(${InterMedium}) format('truetype');
    }
    @font-face {
    	font-family: "Inter";
      font-weight: 600;
    	src: url(${InterSemiBold}) format('truetype');
    }
    @font-face {
    	font-family: "Inter";
      font-weight: 700;
    	src: url(${InterBold}) format('truetype');
    }
    @font-face {
    	font-family: "Inter";
      font-weight: 800;
    	src: url(${InterExtraBold}) format('truetype');
    }

    //Colours
    :root {
        --black: #18191F;
        --orange: #E25215;
        --orange2: #FFDDCF;
        --peach: #FFF8F4;
        --peach2: #FFEAE1;
        --violet: #2C0463;
        --blue: #9FCAFF;
        --blueChalk: #E1EEFF;
        --babyBlue: #ACD1FF;
        --blueDress: #1778F2;

    }

    html {
		scroll-behavior: smooth;
	}

	body {
        color: var(--black);
		margin: 0;
		font-family: 'Inter', 'Segoe UI', sans-serif;
	}

    main {
        margin-top: 121px;
        & > section {
            margin-bottom: 5rem;
            &:last-child {
                margin-bottom: 0;
            }
            @media (max-width: 768px) {
                margin-bottom: 3rem;
                &:last-child {
                    margin-bottom: 2rem;
                }
            }
        }
        @media (max-width: 768px) {
            margin-top: 75px
        }
    }

    svg {
        overflow: initial;
    }

    h1, h2, h3, h4, h5, h6, p {
        margin: 0;
    }

    h1 {
        font-size: 60px;
        font-weight: 600;
    }

    h2 {
        font-size: 45px;
        font-weight: 700;
        @media (max-width: 768px) {
            font-size: 34px;
        }
    }

    h3 {
        font-size: 40px;
        font-weight: 600;
    }

    h4 {
        font-size: 28px;
        font-weight: 600;
    }

    h5 {
        font-size: 24px;
        font-weight: 600;
    }

    h6 {
        font-size: 20px;
        font-weight: 600;
    }

    a {
        text-decoration: none;
        color: inherit;
        &:hover {
            color: var(--blueChalk)
        }
    }

    input, button {
        appearance: none;
        outline: none;
    }

    /*
        138px 600 - Home Hero
        60px 700
        48px 800
        40px 700 - Home Hero
        30px 500
        28px
        24px 600
        20px 600 - Nav
        18px 400
        16px 500 - Nav (Btn)
        14px 400
    */

`;

export default GlobalStyle;
