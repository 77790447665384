import React from 'react'
import GlobalStyles from '../globalStyles'
import Footer from './Footer'
import Header from './Header'

const Layout = (props) => {
    return (
        <>
          <GlobalStyles />
          <Header />
          <main>
            {props.children}
          </main>
          <Footer />
        </>
    )
}

export default Layout
