import { Link } from "gatsby";
import React, { useState } from "react";
import styled from "styled-components";

import Logo from "../icons/logo.svg";

const StyledWrapper = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  background-color: white;
  // box-shadow: 0px 1px 0px rgba(226, 82, 21, 0.2);

  .row {
    padding: 2.25rem 0;
  }

  .logo {
    position: relative;
    bottom: 4px;
  }

  nav > * + * {
    margin: 0 0 0 4rem;
  }

  button {
    background-color: var(--orange);
    color: white;
    border: none;
    padding: 0.75em 4.5em;
    border-radius: 50px;
    &:disabled {
      background-color: var(--orange2);
    }
  }

  svg line {
    stroke: var(--orange);
  }

  //---Material UI---
  .MuiPaper-root {
    background-color: transparent;
    color: black;
    border: none;
    box-shadow: unset;
    border-radius: unset !important;
  }

  .MuiAccordion-root::before {
    display: none;
  }

  .MuiAccordionSummary-root {
    padding: 16px 0;
  }

  .MuiAccordionDetails-root {
    padding: 3rem 0;
    justify-content: center;
  }

  .MuiAccordionSummary-content,
  .MuiAccordion-root.Mui-expanded,
  .MuiAccordionSummary-content.Mui-expanded {
    margin: 0;
  }

  .MuiAccordionSummary-expandIcon.Mui-expanded {
    transform: rotate(135deg);
  }

  @media (max-width: 768px) {
    // .logo {
    //   width: 72px;
    //   height: 34px;
    // }

    .logo {
      position: relative;
      bottom: 4px;
    }

    nav > * + * {
      margin: 3rem 0 0;
    }
  }
`;

const Header = () => {
  const [mobileExpand, setMobileExpand] = useState(false);

  return (
    <>
      <StyledWrapper>
        <div className="container">
          {/* Desktop */}
          <div className="row position-relative">
            <div className="col-lg-3 col-2">
              <Link to="/">
                <Logo className="logo" />
              </Link>
            </div>
          </div>

          {/* Mobile */}
          {/* <Accordion
            expanded={mobileExpand}
            classes={{ root: "d-md-none" }}
            square={true}
          >
            <AccordionSummary
              aria-controls="panel1a-content"
              id="panel1a-header"
              expandIcon={
                mobileExpand ? (
                  <Plus onClick={() => setMobileExpand(!mobileExpand)} />
                ) : (
                  <HamMenu onClick={() => setMobileExpand(!mobileExpand)} />
                )
              }
            >
              <div className="col-2">
                <Link to="/">
                  <Logo className="logo" />
                </Link>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <nav className="d-flex flex-column align-items-center">
                <Link to="/team">
                  <h6>Team</h6>
                </Link>
                <Link to="/careers">
                  <h6>Careers</h6>
                </Link>
                <button
                  onClick={() => {
                    setMobileExpand(false);
                  }}
                >
                  Get Started
                </button>
              </nav>
            </AccordionDetails>
          </Accordion> */}
        </div>
      </StyledWrapper>
    </>
  );
};

export default Header;
