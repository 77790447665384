import React from "react";
import styled from "styled-components";
import Logo from "../icons/logo.svg";
import FacebookIcon from "../icons/Facebook.svg";
import LinkedInIcon from "../icons/LinkedIn.svg";
import { Link } from "gatsby";

const StyledWrapper = styled.footer`
  background-color: var(--blueDress);
  color: white;

  .logo svg {
    position: relative;
    bottom: 4px;
  }

  svg path {
    fill: white;
  }

  h6 {
    font-weight: 500;
  }

  a + a {
    margin: 0 0 0 5rem;
  }

  span {
    font-size: 14px;
  }

  .row + .row {
    border-top: 1px solid;
  }

  .contact_us_container {
    margin: 0 5rem 0 0;
  }

  .contact_us {
    font-size: 20px;
    font-weight: 500;
  }

  .last_container {
    display: flex;
    justify-content: space-between;
  }

  .pageLinks,
  .social,
  .copyright {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .pageLinks,
  .social {
    justify-content: flex-end;
  }

  .copyright {
    justify-content: flex-start;
  }

  .social > a + a {
    margin: 0 0 0 2rem;
  }

  .gst_cin {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    gap: 30px;

    font-size: 14px;
    font-weight: 400;
    @media (max-width: 992px) {
      gap: 10px;
    }
  }

  @media (max-width: 992px) {
    .logo {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    a + a {
      margin: 1rem 0 0;
    }

    .pageLinks {
      flex-direction: column;
      justify-content: unset;
    }

    .last_container {
      flex-direction: column;
      justify-content: unset;
    }

    .social,
    .copyright {
      justify-content: center;
    }

    .social > a + a {
      margin: 0 0 0 3rem;
    }

    .row + .row {
      border-top: unset;
    }

    span {
      text-align: center;
    }

    .contact_us_container {
      margin: 0 0 1rem;
      text-align: center;
    }
  }
`;

const Footer = () => {
  return (
    <StyledWrapper>
      <div className="container py-lg-0 py-5">
        <div className="row py-lg-4">
          <div className="col-lg-3 logo ps-lg-0 mb-lg-0 mb-5">
            <Link to="/">
              <Logo />
            </Link>
          </div>
          <div className="col-lg-9 pageLinks pe-lg-0 mb-lg-0 mb-5">
            <div className="contact_us_container">
              <div className="contact_us">Contact Us</div>
              <a href="mailto:grievance@niro.co.in">grievance@niro.co.in</a>
            </div>
            <Link to="/terms-of-use">
              <h6>Terms of Use</h6>
            </Link>
            <Link to="/privacy-policy">
              <h6>Privacy Policy</h6>
            </Link>
          </div>
        </div>
        <div className="row flex-lg-row  py-lg-4">
          <div className="last_container">
            <div className=" copyright ">
              <span>
                © {new Date().getFullYear()} Niro Capital Private Limited. All
                rights reserved.
              </span>
            </div>
            <div className="copyright">
              <span>CIN - U65999KA2021PTC155709</span>
            </div>
          </div>
        </div>
      </div>
    </StyledWrapper>
  );
};

export default Footer;
